import React, { useState, useEffect } from "react";
import "./style.scss";
import "./ekka.scss";
import SideBar from "./sidebar";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import { Modal, Button, Spin, message, Form, Upload } from "antd";
import axios from "axios";
import { Select } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditSubCategoryModal from "./EditProduct";
import { DatePicker } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import moment from "moment";
import { uid } from "chart.js/helpers";



const { confirm } = Modal;

const DeleteCategoryModal = ({ visible, onCancel, onConfirm }) => {
  return (
    <Modal
      open={visible}
      title="Confirm Deletion"
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="delete" type="primary" onClick={onConfirm}>
          Delete
        </Button>,
      ]}
    >
      <p>Are you sure you want to delete this pincode?</p>
    </Modal>
  );
};
const AddMembers = () => {
  const token = localStorage.getItem("token");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [fileid, setFileid] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [categoryList, setCategoriesList] = useState([]);
  const [mpAreaList, setMpAreaList] = useState([]);
  const [mlaAreaList, setMlaAreaList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [firstname, setFirstname] = useState("");
  const [fathername, setFathername] = useState("");
  const [dob, setDob] = useState(null);
  const [uid, setUid] = useState('');
  const [address, setAddress] = useState("");
  const [area, setArea] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [mparea, setMparea] = useState("");
  const [mlaarea, setMlaarea] = useState("");
  const [gender, setGender] = useState("");
  const [occupation, setOccupation] = useState("");
  const [married, setMarried] = useState("");
  const [spousename, setSpousename] = useState("");
  const [showOccupationInput, setShowOccupationInput] = useState(false);
  const [errors, setErrors] = useState({});
  const handleEditCategory = (category) => {
    setSelectedCategory(category);
    setShowEditModal(true);
  };
  const handleCloseEditModal = () => {
    setSelectedCategory(null);
    setShowEditModal(false);
  };

  const handleDeleteCategoryConfirmed = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `https://api.nagaratharcoc.com/api/v1/pincode/delete`,
        {
          pincodeid: selectedCategory,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        fetchData();
      } else {
        console.error("Failed to delete category");
      }
    } catch (error) {
      console.error("Error deleting category:", error);
    } finally {
      setLoading(false);
      setShowDeleteModal(false);
    }
  };
  const handleSaveEdit = async (editedData) => {
    try {
      const confirmResult = await new Promise((resolve) => {
        confirm({
          title: "Do you want to save the changes?",
          onOk() {
            resolve(true);
          },
          onCancel() {
            resolve(false);
          },
        });
      });

      if (!confirmResult) {
        return;
      }
      setLoading(true);
      editedData.pincodeid = selectedCategory.pincodeid;
      editedData.country = editedData.country;
      editedData.state = editedData.state;
      editedData.pincode = editedData.pincode;
      const response = await axios.post(
        `https://api.nagaratharcoc.com/api/v1/pincode/edit`,
        editedData,
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 401 || response.status === 404) {
        navigate("/login");
        return;
      }

      if (response.status === 200) {
        console.log("Subcategory updated successfully");
        window.location.reload();
      } else {
        console.error("PUT request failed");
      }
    } catch (error) {
      console.error("Error updating data:", error);
    } finally {
      setLoading(false);
      handleCloseEditModal();
    }
  };
  // useEffect(() => {
  //   const fetchCategoryNames = async () => {
  //     try {
  //       const response = await axios.put(
  //         "https://api.shopeasey.com/api/v1/category/get",
  //         {},
  //         {
  //           headers: {
  //             Authorization: "Bearer " + token,
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );
  //       if (response.status === 401 || response.status === 404) {
  //         navigate("/AdminLogin");
  //         return;
  //       }
  //       setCategoriesList(response.data);
  //     } catch (error) {
  //       console.error("Error fetching names:", error);
  //     }
  //   };
  //   fetchCategoryNames();
  // }, []);

  useEffect(() => {
    const fetchMpArea = async () => {
      try {
        const response = await axios.get(
          "https://api.yositechmedia.com/api/v1/mp-areas/getall",
          {
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
          }
        );

        // Redirect if token is invalid or unauthorized
        if (response.status === 401 || response.status === 404) {
          navigate("/AdminLogin");
          return;
        }

        setMpAreaList(response.data);
      } catch (error) {
        console.error("Error fetching MP Areas:", error);
      }
    };

    if (token) {
      fetchMpArea(); // Only fetch if token is present
    } else {
      navigate("/AdminLogin"); // Redirect if no token found
    }
  }, [token, navigate]);

  const fetchMlaArea = async (mpAreaId) => {
    try {
      const response = await axios.get(
        `https://api.yositechmedia.com/api/v1/mp-areas/${mpAreaId}`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 401 || response.status === 404) {
        navigate("/AdminLogin");
        return;
      }

      const mlaAreas = response.data.areaModels || []; // Ensure it is always an array
      setMlaAreaList(mlaAreas);
    } catch (error) {
      console.error("Error fetching MLA Areas:", error);
      setMlaAreaList([]); // Handle error by resetting the list
    }
  };

  const handleMpAreaChange = (e) => {
    const selectedMpAreaId = e.target.value;
    setMparea(selectedMpAreaId);
    if (selectedMpAreaId) {
      fetchMlaArea(selectedMpAreaId);
    } else {
      setMlaAreaList([]);
    }
  };
  useEffect(() => {
    if (!token) {
      navigate("/AdminLogin");
      return;
    }
    fetchData();
  }, []);

  const first10Pincodes = data.slice(0, 10);
  const fetchData = async () => {
    try {
      const response = await axios.put(
        "https://api.nagaratharcoc.com/api/v1/pincode/get",
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 401 || response.status === 404) {
        navigate("/AdminLogin");
        return;
      }
      setData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  const validateForm = () => {
    let valid = true;
    let errors = {};
    const aadhaarRegex = /^\d{12}$/;
    if (!aadhaarRegex.test(uid)) {
      errors.uid = "Aadhaar card number must be exactly 12 digits.";
      valid = false;
    }
    setErrors(errors);
    return valid;
  }

  const onFinish = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    const token = localStorage.getItem("token");

    try {
      const fileIdsArray = Array.isArray(fileid) ? fileid : [fileid];
      setLoading(true);
      const response = await fetch(
        "https://api.yositechmedia.com/api/v1/member/register",
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ firstname, fathername, dob, uid, address, area, city, state, pincode, mparea, mlaarea, gender, occupation, married, spousename, fileIds: fileIdsArray, }),
        }
      );
      const data = await response.json();
      console.log("data", data);
      if (response.status === 401 || response.status === 404) {
        navigate("/AdminLogin");
        return;
      }
      if (data.status !== "Error") {
        message.success("Member added successfully");
        navigate('/viewmembers');
      } else {
        setLoading(false);
        console.log(data.description);
        toast(data.description, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.error("Error posting data:", error);
      setLoading(false);
    }
  };
  const handleFileUploadforMultipleImages = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        "https://api.yositechmedia.com/api/v1/member/uploadgallery?file",
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            onProgress({ percent: percentCompleted });
          },
          headers: {
            Authorization: 'Bearer ' + token,
            // 'Content-Type': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
          },
        }
      );
      if (response.status === 401 || response.status === 404 || !token) {
        navigate('/AdminLogin');
        return;
      }
      const organizerId = response.data.fileid;
      console.log(organizerId)
      setFileid((prevUploadFiles) => [...prevUploadFiles, response.data.fileid]);
      setFileList(prevFileList => [
        ...prevFileList,
        { fid: response.data.fileid, name: file.name, status: 'done', url: response.data.uploadedUrl }
      ]);
      onSuccess("File uploaded");
    } catch (error) {
      onError("File upload failed");
    }
  };

  return (
    <div className="wrapper">
      <SideBar />
      <Header />
      <div className="ec-content-wrapper">
        <div className="content" style={{ marginTop: "50px" }}>
          <div className="breadcrumb-wrapper breadcrumb-wrapper-2 breadcrumb-contacts">
            <h1>VOTERS</h1>
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-12 ms-auto me-auto">
              <div className="ec-cat-list card card-default mb-24px">
                <div className="card-body">
                  <div className="ec-cat-form">
                    <h4 className="text-truncate">ADD</h4>
                    <form onSubmit={onFinish}>
                      <div className="form-group row">
                        <label>Name</label>
                        <div className="col-12">
                          <input
                            className="form-control"
                            type="text"
                            value={firstname}
                            onChange={(e) => setFirstname(e.target.value)}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label>Father Name</label>
                        <div className="col-12">
                          <input
                            className="form-control"
                            type="text"
                            value={fathername}
                            onChange={(e) => setFathername(e.target.value)}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label>Gender</label>
                        <div className="col-12">
                          <select
                            className="form-control"
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                          >
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Others">Others</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label>Occupation</label>
                        <div className="col-12">
                          <select
                            className="form-control"
                            value={occupation}
                            onChange={(e) => {
                              if (e.target.value === "Other") {
                                setOccupation("");
                                setShowOccupationInput(true);
                              } else {
                                setOccupation(e.target.value);
                                setShowOccupationInput(false);
                              }
                            }}
                          >
                            <option value="">Select Occupation</option>
                            <option value="Private Job">Private Job</option>
                            <option value="Gov Job">Gov Job</option>
                            <option value="Business">Business</option>
                            <option value="Other">Other</option>
                          </select>
                        </div>
                        {showOccupationInput && (
                          <div className="col-12 mt-2">
                            <input
                              className="form-control"
                              type="text"
                              value={occupation}
                              onChange={(e) => setOccupation(e.target.value)}
                              placeholder="Enter your occupation"
                            />
                          </div>
                        )}
                      </div>
                      <div className="form-group row">
                        <label>Address</label>
                        <div className="col-12">
                          <input
                            className="form-control"
                            type="text"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label>MP Area</label>
                        <div className="col-12">
                          <select
                            className="form-control"
                            value={mparea}
                            onChange={handleMpAreaChange}
                          >
                            <option value="">Select MP Area</option>
                            {mpAreaList.map((area) => (
                              <option key={area.mpid} value={area.mpid}>
                                {area.area}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label>MLA Area</label>
                        <div className="col-12">
                          <select
                            className="form-control"
                            value={mlaarea}
                            onChange={(e) => setMlaarea(e.target.value)}
                            disabled={!mparea}
                          >
                            <option value="">Select MLA Area</option>
                            {mlaAreaList.length > 0 ? (
                              mlaAreaList.map((area) => (
                                <option key={area.mlaid} value={area.mlaid}>
                                  {area.area}
                                </option>
                              ))
                            ) : (
                              <option disabled>No MLA Areas Available</option>
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label>City</label>
                        <div className="col-12">
                          <input
                            className="form-control"
                            type="text"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label>State</label>
                        <div className="col-12">
                          <input
                            className="form-control"
                            type="text"
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label>Pincode</label>
                        <div className="col-12">
                          <input
                            className="form-control"
                            type="text"
                            value={pincode}
                            onChange={(e) => setPincode(e.target.value)}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label>Aadhaar Number</label>
                        <div className="col-12">
                          <input
                            className="form-control"
                            type="text" // Use "text" type for better control over input
                            value={uid}
                            onChange={(e) => {
                              const input = e.target.value.replace(/\D/g, '');
                              setUid(input);
                            }}
                            maxLength="12"
                            inputMode="numeric"
                            autoComplete="off"
                            placeholder="Enter 12-digit Aadhaar number"
                          />
                          {errors.uid && <p style={{ color: 'red' }}>{errors.uid}</p>}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label>Married</label>
                        <div className="col-12">
                          <select
                            className="form-control"
                            value={married}
                            onChange={(e) => setMarried(e.target.value)}
                          >
                            <option value="">Select Status</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>
                      </div>
                      {married === "Yes" && (
                        <div className="form-group row">
                          <label>Spouse Name</label>
                          <div className="col-12">
                            <input
                              className="form-control"
                              type="text"
                              value={spousename}
                              onChange={(e) => setSpousename(e.target.value)}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                      )}
                      <div className="form-group row">
                        <label>Date of Birth</label>
                        <div className="col-12">
                          <DatePicker
                            value={dob ? moment(dob, "DD/MM/YYYY") : null}
                            onChange={(dob) => setDob(dob ? dob.format("DD/MM/YYYY") : null)}
                            format="DD/MM/YYYY"
                            className="form-control"
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label>Multiple Images Upload</label>
                        <div className="col-12">
                          <Form.Item
                            name="fileid"
                          >
                            <Upload
                              customRequest={handleFileUploadforMultipleImages}
                              listType="picture"
                              fileList={fileList} // Use fileList to manage the uploaded files
                              onRemove={(file) => {
                                setFileList(fileList.filter((item) => item.fid !== file.fid));
                              }}
                            >
                              <Button icon={<UploadOutlined />} style={{ top: '0px', borderRadius: '0px' }}>
                                Click to Upload
                              </Button>
                            </Upload>
                            {/* <div className="uploaded-files">
															{renderUploadedFiles()}
														</div> */}
                          </Form.Item>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <button
                            name="submit"
                            type="submit"
                            className="btn btn-primary"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >

      {selectedCategory && (
        <EditSubCategoryModal
          show={showEditModal}
          onHide={handleCloseEditModal}
          onSave={handleSaveEdit}
          categoryList={categoryList}
          category={selectedCategory}
        />
      )}
      {
        selectedCategory && (
          <DeleteCategoryModal
            visible={showDeleteModal}
            onCancel={() => setShowDeleteModal(false)}
            onConfirm={handleDeleteCategoryConfirmed}
          />
        )
      }

      <ToastContainer />
    </div >
  );
};
export default AddMembers;
