import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, message } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import "./App.css";

const ForgotPassword = () => {
  const [step, setStep] = useState(1); // 1: Enter Email, 2: Enter OTP & New Password
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const navigate = useNavigate();
	const [loading, setLoading] = useState(true);

  const handleSendOtp = async () => {
    try {
      setLoading(false);
      const response = await fetch('https://api.yositechmedia.com/api/v1/login/sendotp', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        message.success('OTP sent to your email.');
        setStep(2);
      } else {
        message.error('Failed to send OTP.');
      }
      setLoading(true);
    } catch (error) {
      console.error('Error sending OTP:', error);
      message.error('Error sending OTP.');
    }
  };

  const handleVerifyOtpAndResetPassword = async () => {
    try {
      const response = await fetch('https://api.yositechmedia.com/api/v1/login/forgotpassword', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, dob:otp, password:newPassword }),
      });

      if (response.ok) {
        message.success('Password reset successfully.');
        navigate('/AdminLogin');
      } else {
        message.error('Failed to reset password.');
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      message.error('Error resetting password.');
    }
  };

  return (
    <div className="accountbg" style={{pointerEvents: !loading && 'none'}}>
      <div className="login-center">
        <div className="login-wrappere">
          {step === 1 && (
            <Form
              name="send_otp"
              className="login-form"
              onFinish={handleSendOtp}
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your Email ID!",
                  },
                  {
                    type: 'email',
                    message: 'Please enter a valid Email ID!',
                  },
                ]}
              >
                <Input
                  prefix={<MailOutlined className="site-form-item-icon" />}
                  placeholder="Email ID"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType="submit"
                  className="login-form-button"
                  style={{ width: "100%" }}
                >
                  Send OTP
                </Button>
              </Form.Item>
            </Form>
          )}
          {step === 2 && (
            <Form
              name="reset_password"
              className="login-form"
              onFinish={handleVerifyOtpAndResetPassword}
            >
              <Form.Item
                name="otp"
                rules={[
                  {
                    required: true,
                    message: "Please input the OTP!",
                  },
                ]}
              >
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder="OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
              </Form.Item>
              <Form.Item
                name="newPassword"
                rules={[
                  {
                    required: true,
                    message: "Please input your new password!",
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType="submit"
                  className="login-form-button"
                  style={{ width: "100%" }}
                >
                  Reset Password
                </Button>
              </Form.Item>
            </Form>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
