import React, { useState } from 'react';
import SideBar from './sidebar';
import Header from './Header';

const Area = () => {
    const [mparea, setMparea] = useState('');
    const [mlaarea, setMlaarea] = useState('');
    const [mlaList, setMlaList] = useState([]);

    const handleAddMla = () => {
        if (mlaarea) {
            setMlaList([...mlaList, mlaarea]);
            setMlaarea('');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('MP Area:', mparea);
        console.log('MLA Areas:', mlaList);
    };

    return (
        <div className="wrapper">
            <SideBar />
            <Header />
            <div className="ec-content-wrapper">
                <div className="content" style={{ marginTop: "50px" }}>
                    <div className="breadcrumb-wrapper breadcrumb-wrapper-2 breadcrumb-contacts">
                        <h1>AREAS</h1>
                    </div>
                    <div className="row">
                        <div className="col-xl-4 col-lg-12 ms-auto me-auto">
                            <div className="ec-cat-list card card-default mb-24px">
                                <div className="card-body">
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-3">
                                            <label className="form-label">MP Area</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={mparea}
                                                onChange={(e) => setMparea(e.target.value)}
                                                placeholder="Enter MP Area"
                                                required
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">MLA Area</label>
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={mlaarea}
                                                    onChange={(e) => setMlaarea(e.target.value)}
                                                    placeholder="Enter MLA Area"
                                                />
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={handleAddMla}
                                                >
                                                    Add
                                                </button>
                                            </div>
                                        </div>
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </form>
                                    <div className="mt-4">
                                        <h5>MLA Areas List:</h5>
                                        <ul>
                                            {mlaList.map((area, index) => (
                                                <li key={index}>{area}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default Area;