// Header.js
import React, { useState, useEffect } from 'react';
import './style.scss';
import './ekka.scss';
import { Link, useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import logo from './img/naga-logo.png';

function Header() {

	const navigate = useNavigate();
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [isTokenExpired, setIsTokenExpired] = useState(false);
	const toggleDropdown = () => {
		setIsDropdownOpen(!isDropdownOpen);
	};
	useEffect(() => {
		const token = localStorage.getItem('token');

		if (token) {
			const decodedToken = jwtDecode(token);
			const currentTime = Date.now() / 1000;

			if (decodedToken.exp < currentTime) {
				// Token has expired
				setIsTokenExpired(true);
				localStorage.removeItem('token');
				navigate("/AdminLogin")
			} else {
				// Token is still valid
				setIsTokenExpired(false);
			}
		}
	}, []);
	const handleLogout = () => {
		// Clear all relevant items from localStorage
		localStorage.removeItem("token");
		localStorage.removeItem("id");
		localStorage.removeItem("firstname");
		localStorage.removeItem("email");
		localStorage.removeItem("role");
	
		// Optionally, you can clear all of localStorage if needed
		// localStorage.clear();
	
		// Redirect to login page
		navigate("/AdminLogin");
	  };

	return (
		<header className="ec-main-header" id="header">
			<nav className="navbar navbar-static-top navbar-expand-lg">
				{/* Left section with only the WELCOME text */}
				<div className="navbar-left">
					<h1 className="welcome-text">WELCOME</h1>
				</div>

				{/* Right section with Search bar and Profile dropdown */}
				<div className="navbar-right">
					<div className="search-form">
						<input type="text" className="search-input" placeholder="Search..." />
					</div>
					<ul className="nav navbar-nav">
						<li className={`dropdown user-menu ${isDropdownOpen ? 'open' : ''}`}>
							<button
								className="dropdown-toggle nav-link ec-drop"
								data-bs-toggle="dropdown"
								onClick={toggleDropdown}
								aria-expanded="false"
							>
								{/* <img src={logo} className="user-image" alt="User Icon" /> */}{localStorage.getItem("firstname")}
							</button>
							<ul className={`dropdown-menu dropdown-menu-right ec-dropdown-menu ${isDropdownOpen ? 'show' : ''}`}>
								{/* <li className="dropdown-header">
									<div className="d-inline-block">
										Admin <small className="pt-1"></small>
									</div>
								</li> */}
								<li>
									<Link to="/profile">
										<i className="mdi mdi-account"></i> Profile
									</Link>
								</li>
								<li className="dropdown-footer">
									<Link to="/AdminLogin" onClick={handleLogout}>
										<i className="mdi mdi-logout"></i> Log Out
									</Link>
								</li>
							</ul>
						</li>
					</ul>
				</div>
			</nav>
		</header>
	);
}

export default Header;
