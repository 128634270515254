import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./style.scss";
import "./ekka.scss";
import SideBar from "./sidebar";
import Header from "./Header";
import { Select } from "antd";
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const { Option } = Select;

const Dashboard = () => {
    const navigate = useNavigate();
    const token = localStorage.getItem("token");
    const [data, setData] = useState([]);
    const [datas, setDatas] = useState([]);
    const [datass, setDatass] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [totalMembers, setTotalMembers] = useState(0);
    const [under18, setUnder18] = useState(0);
    const [totalUsers, setTotalUsers] = useState(0);
    const [above18, setAbove18] = useState(0);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState([]);

    useEffect(() => {
        if (data.length > 0) {
            processChartData(data);
        }
    }, [data]);
    useEffect(() => {
        if (!token) {
            navigate("/AdminLogin");
            return;
        }
        fetchStat();
        fetchData();
        fetchLastTen();
        fetchUser();
        fetchUpcomingBirthdays();
    }, [token, navigate]);

    const fetchLastTen = async () => {
        try {
            const response = await axios.get(
                "https://api.yositechmedia.com/api/v1/member/last10",
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.status === 401 || response.status === 404) {
                navigate("/AdminLogin");
                return;
            }
            setDatas(response.data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
        }
    };
    const fetchUpcomingBirthdays = async () => {
        try {
            const response = await axios.get(
                "https://api.yositechmedia.com/api/v1/member/birthdays",
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.status === 401 || response.status === 404) {
                navigate("/AdminLogin");
                return;
            }
            setDatass(response.data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
        }
    };
    const fetchStat = async () => {
        try {
            const response = await axios.get(
                "https://api.yositechmedia.com/api/v1/statistics/stats",
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const { totalMembers, under18, totalUsers, above18 } = response.data;
            setTotalMembers(totalMembers);
            setUnder18(under18);
            setTotalUsers(totalUsers);
            setAbove18(above18);
            setLoading(false);
        } catch (error) {
            if (error.response && (error.response.status === 401)) {
                navigate("/AdminLogin");
            } else {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        }
    };

    const fetchData = async () => {
        try {
            const response = await axios.post(
                "https://api.yositechmedia.com/api/v1/member/get",
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );
            if (response.status === 401 || response.status === 404) {
                navigate("/AdminLogin");
                return;
            }
            setData(response.data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
        }
    };
    const fetchUser = async () => {
        try {
            const response = await axios.post(
                "https://api.yositechmedia.com/api/v1/user/get",
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );
            if (response.status === 401 || response.status === 404) {
                navigate("/AdminLogin");
                return;
            }
            setUser(response.data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
        }
    };

    const processChartData = (members) => {
        const monthMap = {};

        members.forEach((member) => {
            const createDate = moment(member.createDate);
            const month = createDate.format('YYYY-MM');
            if (monthMap[month]) {
                monthMap[month] += 1;
            } else {
                monthMap[month] = 1;
            }
        });

        const chartData = Object.keys(monthMap).map((month) => ({
            month,
            membersAdded: monthMap[month],
        }));

        setChartData(chartData); 
    };

    const processData = () => {
        const monthCounts = {};

        data.forEach((member) => {
            const date = new Date(member.createDate);
            const monthYear = `${date.getMonth() + 1}-${date.getFullYear()}`;

            if (!monthCounts[monthYear]) {
                monthCounts[monthYear] = 0;
            }
            monthCounts[monthYear]++;
        });

        const sortedMonths = Object.keys(monthCounts).sort((a, b) => {
            const [monthA, yearA] = a.split('-').map(Number);
            const [monthB, yearB] = b.split('-').map(Number);
            return new Date(yearA, monthA) - new Date(yearB, monthB);
        });

        const chartLabels = sortedMonths;
        const chartDatas = sortedMonths.map((month) => monthCounts[month]);

        return {
            labels: chartLabels,
            datasets: [
                {
                    label: 'Members Added Per Month',
                    data: chartDatas,
                    borderColor: 'rgba(75,192,192,1)',
                    backgroundColor: 'rgba(75,192,192,0.2)',
                    fill: true,
                },
            ],
        };
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    const chartDataLine = processData();

    const commonChartDimensions = { width: "100%", height: 300 };

    return (
        <div className="wrapper">
            <SideBar />
            <Header />
            <div className="ec-content-wrapper">
                <div className="content" style={{ marginTop: "40px" }}>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="card text-center">
                                <div className="card-body">
                                    <div className="row align-items-center">
                                        <div className="col-4">
                                            <i className="mdi mdi-account-group mdi-48px"></i>
                                        </div>
                                        <div className="col-4"></div>
                                        <div className="col-4">
                                            <h4>{totalUsers}</h4>
                                            <p className="mb-0">Total Users</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card text-center">
                                <div className="card-body">
                                    <div className="row align-items-center">
                                        <div className="col-4">
                                            <i className="mdi mdi-account mdi-48px"></i>
                                        </div>
                                        <div className="col-4"></div>
                                        <div className="col-4">
                                            <h4>{under18}</h4>
                                            <p className="mb-0">Below 18</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card text-center">
                                <div className="card-body">
                                    <div className="row align-items-center">
                                        <div className="col-4">
                                            <i className="mdi mdi-account mdi-48px"></i>
                                        </div>
                                        <div className="col-4"></div>
                                        <div className="col-4">
                                            <h4>{above18}</h4>
                                            <p className="mb-0">Above 18</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="card text-center">
                                <div className="card-body">
                                    <div className="row align-items-center">
                                        <div className="col-4">
                                            <i className="mdi mdi-account-group mdi-48px"></i>
                                        </div>
                                        <div className="col-4"></div>
                                        <div className="col-4">
                                            <h4>{totalMembers}</h4>
                                            <p className="mb-0">Total Members</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="chart p-3">
                                <h3 className="ec-brand-name text-truncate">Members Added Per Month</h3>
                                <ResponsiveContainer {...commonChartDimensions}>
                                    <BarChart data={chartData}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="month" />
                                        <YAxis />
                                        <Tooltip />
                                        <Bar dataKey="membersAdded" fill="#2ccfde" />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="chart p-3">
                                <h3 className="ec-brand-name text-truncate">Member Additions Over Time</h3>
                                <ResponsiveContainer {...commonChartDimensions}>
                                    <Line data={chartDataLine} className="line-graph" />
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="pt-3">
                                <h3 className="ec-brand-name text-truncate">Latest Added Members</h3>
                                <div className="row">
                                    <div className="col">
                                        <table className="table table-striped">
                                            <thead>
                                            <tr>
                                                    <th>S.No</th>
                                                    <th>Name</th>
                                                    <th>Gender</th>
                                                    <th>Aadhaar No</th>
                                                    <th>DOB</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Array.isArray(datas) && datas.map((member,index) => (
                                                    <tr key={member.id}>
                                                        <td>{index+1}</td>
                                                        <td>{member.firstname}</td>
                                                        <td>{member.gender}</td>
                                                        <td>{member.uid}</td>
                                                        <td>{member.dob}</td>
                                                        <td>{new Date(member.createDate).toLocaleDateString()}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="pt-3">
                                <h3 className="ec-brand-name text-truncate">Upcoming Birthdays</h3>
                                <div className="row">
                                    <div className="col">
                                        <table className="table table-striped">
                                            <thead>
                                            <tr>
                                                    <th>S.No</th>
                                                    <th>Name</th>
                                                    <th>Gender</th>
                                                    <th>Aadhaar No</th>
                                                    <th>DOB</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Array.isArray(datass) && datass.map((member,index) => (
                                                    <tr key={member.id}>
                                                        <td>{index+1}</td>
                                                        <td>{member.firstname}</td>
                                                        <td>{member.gender}</td>
                                                        <td>{member.uid}</td>
                                                        <td>{member.dob}</td>
                                                        <td>{new Date(member.createDate).toLocaleDateString()}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
